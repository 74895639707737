import React, { useRef, useEffect, useState } from "react";
import "./MessageList.scss";
import { Message } from "../Messages/Messages";
import TypingIndicator from "../TypingIndicator/TypingIndicator";

export const MessageList = ({
  messages,
  isTyping,
  onSampleQuestionClick,
}) => {
  const messageListRef = useRef(null);
  const lastMessage = messages[messages.length - 1];
  const showTypingIndicator =
    isTyping &&
    (!lastMessage ||
      lastMessage.role !== "assistant" ||
      lastMessage.content === "");
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  // Scroll to the bottom of the chat container
  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current?.scrollHeight;
    }
  };

  // Automatically scroll to the bottom when new messages are added
  useEffect(() => {
    if (!isUserScrolling) {
      scrollToBottom();
    }
  }, [messages]);

  // Track user scroll behavior
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      messageListRef.current || {};
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;
    setIsUserScrolling(!isAtBottom);
  };

  return (
    <div className="message-list" ref={messageListRef} onScroll={handleScroll}>
      {messages.map((message, index) =>
        message && message.content ? (
          <Message
            key={message?.id ?? index}
            role={message.role}
            content={message.content}
            sampleQuestions={message.sampleQuestions}
            onSampleQuestionClick={onSampleQuestionClick}
          />
        ) : null
      )}
      {showTypingIndicator && (
        <TypingIndicator />
      )}
    </div>
  );
};
