import React from 'react'

function TypingIndicator() {
    return <div className="message assistant">
        <div className="message-content typing-indicator">
            ArcoAI is thinking...
        </div>
    </div>
}

export default TypingIndicator